const fetchIntercept = typeof window !== 'undefined' ? require('fetch-intercept') : null

export default function authenticationIntercept (store) {
  if (fetchIntercept) {
    fetchIntercept.register({
      request (url, config) {
        const { masquerade } = store.getState().user

        config = config || {}
        config.headers = config.headers ?? {}

        if (masquerade.userId && config) {
          config.headers['X-Masquerade'] = masquerade.userId
          config.headers['X-Masquerade-Scheme-Year'] = masquerade.schemeYear
        }

        if (config && store.getState().user.accessToken) {
          config.headers.Authorization = `Bearer ${store.getState().user.accessToken}`
          config.headers['X-Bearer-Token'] = `Bearer ${store.getState().user.accessToken}`
        }

        return [url, config]
      },
      response (response) {
        if (response.status === 401) {
          store.dispatch({
            type: 'user/LOGOUT'
          })
        }
        return response
      }
    })
  }
}
